import React from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Form, Row, Col, Container } from "react-bootstrap";

export default function GridNotas(props) {

    const ambiente = 'P' //Produção ou Homologação
    const urlBase = ambiente == 'H' ? 'https://homologacao.focusnfe.com.br' : 'https://api.focusnfe.com.br'

    const colunas = [
        {
            name: 'Status',
            selector: row => row.nfe_status.toUpperCase(),
            sortable: true,
            wrap: true,
            // maxWidth: '120px'
        },
        {
            name: 'Status Sefaz',
            selector: row => row.nfe_status_sefaz,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Mensagem Sefaz',
            selector: row => row.nfe_mensagem_sefaz,
            sortable: true,
            wrap: true
        },
        {
            name: 'Chave',
            selector: row => row.nfe_chave_nfe,
            sortable: true,
            wrap: true
        },
        {
            name: 'Número',
            selector: row => row.nfe_numero,
            sortable: true,
            right: true,
            wrap: true,
            maxWidth: '40px'
        },
        {
            name: 'Série',
            selector: row => row.nfe_serie,
            sortable: true,
            right: true,
            wrap: true,
            maxWidth: '40px'
        },
        {
            name: 'Protocolo',
            selector: row => row.nfe_protocolo,
            sortable: true,
            right: true,
            wrap: true,
            maxWidth: '40px'
        },


    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const tableDataConfig = {
        columns: colunas,
        data: props.data,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: false,
        export: false,
        filterDigit: 1
    };

    return (
        // <DataTableExtensions {...tableDataConfig}>
        //     <DataTable
        //         striped={true}
        //         highlightOnHover={true}
        //         responsive={true}
        //         onRowClicked={(row) => {
        //             if (row.nfe_caminho_danfe) {
        //                 window.open(
        //                     `${urlBase}${row.nfe_caminho_danfe}`,
        //                 )
        //             }

        //         }}
        //         // dense
        //         pointerOnHover={true}
        //         noDataComponent={"Nenhuma Mensagem Encontrada =/"}
        //         pagination={true}
        //         paginationComponentOptions={paginationComponentOptions}
        //     />
        // </DataTableExtensions>
        <Container>
            <Row style={{ marginTop: '30px' }}>
                <Col lg={2}>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Status Sefaz</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_status_sefaz} />
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Descrição Status</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_status.toUpperCase()} />
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Mensagem Sefaz</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_mensagem_sefaz} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Referência</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_ref} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Número</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_numero} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Série</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_serie} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Protocolo</Form.Label>
                        <Form.Control size="sm" id="disabledTextInput" disabled value={props.data[0]?.nfe_protocolo} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.data[0]?.nfe_caminho_danfe &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">DANFE

                                <sup
                                    style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                    onClick={() => {
                                        window.open(
                                            `${urlBase}${props.data[0]?.nfe_caminho_danfe}`,
                                        )
                                    }}
                                >
                                    Acessar
                                </sup>
                            </Form.Label>
                            <Form.Control size="sm" id="disabledTextInput" disabled value={urlBase + props.data[0]?.nfe_caminho_danfe} />
                        </Form.Group>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.data[0]?.nfe_caminho_xml_nota_fiscal &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">XML DANFE
                                <sup
                                    style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                    onClick={() => {
                                        window.open(
                                            `${urlBase}${props.data[0]?.nfe_caminho_xml_nota_fiscal}`,
                                        )
                                    }}
                                >
                                    Acessar
                                </sup>

                            </Form.Label>
                            <Form.Control size="sm" id="disabledTextInput" disabled value={urlBase + props.data[0]?.nfe_caminho_xml_nota_fiscal} />
                        </Form.Group>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.data[0]?.nfe_caminho_pdf_carta_correcao &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">Carta Correção

                                <sup
                                    style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                    onClick={() => {
                                        window.open(
                                            `${urlBase}${props.data[0]?.nfe_caminho_pdf_carta_correcao}`,
                                        )
                                    }}
                                >
                                    Acessar
                                </sup>
                            </Form.Label>
                            <Form.Control size="sm" id="disabledTextInput" disabled value={urlBase + props.data[0]?.nfe_caminho_pdf_carta_correcao} />
                        </Form.Group>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.data[0]?.nfe_caminho_xml_carta_correcao &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">XML Carta Correção

                                <sup
                                    style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                    onClick={() => {
                                        window.open(
                                            `${urlBase}${props.data[0]?.nfe_caminho_xml_carta_correcao}`,
                                        )
                                    }}
                                >
                                    Acessar
                                </sup>

                            </Form.Label>
                            <Form.Control size="sm" id="disabledTextInput" disabled value={urlBase + props.data[0]?.nfe_caminho_xml_carta_correcao} />
                        </Form.Group>
                    }
                </Col>
            </Row>
        </Container>

    )
}