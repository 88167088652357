import React from "react";
import { Container, Modal } from "react-bootstrap";
import TabsPedido from "../tabsPedido";

export default function ModalInfoPedido(props) {
    return (
        <Modal
            show={props.estado}
            fullscreen
            // dialogClassName="modal-grande"
            onHide={() => props.setEstado(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Container>
                        <img src='logo.png' height={40} />
                    </Container>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <TabsPedido
                        pedido={props.pedido}
                        notas={props.notas}
                        cancelarNota={props.cancelarNota}
                        enviarNFE={props.enviarNFE}
                        enviarCC={props.enviarCC}
                        getNotas={props.getNotas}
                        loading={props.loading}
                        setLoading={props.setLoading}
                        estado={props.estado}
                        setEstado={props.setEstado}
                        idPedido={props.idPedido}
                        setIdPedido={props.setIdPedido}
                        modalCC={props.modalCC}
                        setModalCC={props.setModalCC}
                        motivo={props.motivo}
                        setMotivo={props.setMotivo}
                        deletePedido={props.deletePedido}
                        alterarCampo={props.alterarCampo}
                    />
                </Container>
            </Modal.Body>
            {/* <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => props.setEstado(false)}
                >
                    Fechar
                </button>
            </Modal.Footer> */}
        </Modal>
    )
}