import React, { useState, useEffect } from "react";
import { Tabs, Tab, Form, Button, Col, Row, ButtonGroup, Modal } from "react-bootstrap";
import GridProdutos from "../gridProdutos";
import GridNotas from "../gridNotas";

export default function TabsPedido(props) {


    const ambiente = 'P' //Produção ou Homologação
    const urlBase = ambiente == 'H' ? 'https://homologacao.focusnfe.com.br' : 'https://api.focusnfe.com.br'

    const [key, setKey] = useState('pedido');
    const [campoVolume, setCampoVolume] = useState(false);
    const [volume, setVolume] = useState('');
    const [campoInfoAd, setCampoInfoAd] = useState(false);
    const [infoAd, setInfoAd] = useState('');

    useEffect(() => {

        setVolume(props.pedido[0].ped_volumes)
        setInfoAd(props.pedido[0].ped_endereco_entrega)

    }, [])

    return (
        <>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                variant="pills"
            >
                <Tab eventKey="pedido" title="Dados do Pedido">

                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">Pedido</Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].ped_pedido} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">Localizador</Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].ped_localizador} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label
                                        htmlFor="disabledTextInput"
                                    >
                                        Volume(s)
                                        <sup
                                            style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                            onClick={() => {
                                                setCampoVolume(!campoVolume)
                                                if (volume !== props.pedido[0].ped_volumes) {
                                                    props.alterarCampo({
                                                        tabela: 'v2_pedidos',
                                                        campo: 'ped_volumes',
                                                        valor: volume,
                                                        condicao: 'ped_pedido',
                                                        id: props.pedido[0]?.ped_pedido,
                                                    })
                                                }
                                            }}
                                        >
                                            {!campoVolume ? 'Informar' : 'Salvar'}
                                        </sup>
                                    </Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled={!campoVolume} value={volume} onChange={(e) => { setVolume(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label
                                        htmlFor="disabledTextInput"
                                    >
                                        Transportadora

                                    </Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].tran_nome} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">Cliente</Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].clie_nome} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">CNPJ / CPF</Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].clie_cnpj} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">Cidade</Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].clie_municipio} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">UF</Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled value={props.pedido[0].clie_uf} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="disabledTextInput">Informações Adicionais
                                        <sup
                                            style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                            onClick={() => {
                                                setCampoInfoAd(!campoInfoAd)
                                                if (infoAd !== props.pedido[0].ped_endereco_entrega) {
                                                    props.alterarCampo({
                                                        tabela: 'v2_pedidos',
                                                        campo: 'ped_endereco_entrega',
                                                        valor: infoAd,
                                                        condicao: 'ped_pedido',
                                                        id: props.pedido[0]?.ped_pedido
                                                    })
                                                }
                                            }}
                                        >
                                            {!campoInfoAd ? 'Editar' : 'Salvar'}
                                        </sup>
                                    </Form.Label>
                                    <Form.Control size="sm" id="disabledTextInput" disabled={!campoInfoAd} value={infoAd} onChange={(e) => { setInfoAd(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', }}>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    if (window.confirm('Atenção, está ação é irreversível, Tem Certeza?')) {
                                        props.deletePedido(props.pedido[0].ped_pedido)
                                    }
                                }}
                            >
                                Excluir Pedido
                            </Button>
                        </Col>
                    </Row>

                </Tab>
                {/* <Tab eventKey="cliente" title="Dados do Cliente">
                    <h3>Tab Clientes</h3>
                </Tab> */}
                <Tab eventKey="produtos" title="Produtos do Pedido">
                    <GridProdutos
                        data={props.pedido}
                    />
                </Tab>
                <Tab eventKey="nfe" title="Nota Fiscal">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                        <ButtonGroup size="sm">
                            <Button style={{ minWidth: '180px' }} variant="success" onClick={() => {
                                if (props.notas[0]?.nfe_status == 'autorizado') {
                                    alert("NFe Já Autorizada")
                                } else {
                                    props.enviarNFE(props.pedido[0].ped_pedido)
                                }
                            }}>Enviar NFe</Button>
                            <Button style={{ minWidth: '180px' }} variant="warning" onClick={() => {
                                props.getNotas(props.pedido[0].ped_pedido)
                            }}>Consultar Status</Button>
                            <Button style={{ minWidth: '180px' }} variant="danger" onClick={() => {
                                props.cancelarNota(props.pedido[0].ped_pedido)
                            }}>Cancelar NFe</Button>
                            <Button style={{ minWidth: '180px' }} variant="info" onClick={() => {
                                props.setMotivo('')
                                props.setIdPedido(props.pedido[0].ped_pedido)
                                // props.setEstado(false)
                                props.setModalCC(true)

                            }}>Carta de Correção</Button>
                        </ButtonGroup>
                    </div>
                    <GridNotas
                        data={props.notas}
                    />


                </Tab>


            </Tabs>

        </>
    )
}