import React, { useEffect, useState } from "react";
import BarraNavegacao from "./components/navbar";
import ModalInfoPedido from "./components/modalPedido";
import GridPedidos from "./components/gridPedidos";
import GridNFEs from "./components/gridNFEs";
import TabsImportacao from "./components/tabsImportacao";
import { Container, Modal, Form, Button } from "react-bootstrap";
import axios from "axios";

axios.defaults.validateStatus = function (status) {
  return status < 400 || status === 422 || status === 500;
};

function App() {


  const [pedidos, setPedidos] = useState([])
  const [notas, setNotas] = useState([])
  const [nfes, setNfes] = useState([])
  const [janelaInfo, setJanelaInfo] = useState(false)
  const [pedido, setPedido] = useState({})
  const [loading, setLoading] = useState(false)
  const [motivo, setMotivo] = useState('')
  const [idPedido, setIdPedido] = useState('')
  const [modalCC, setModalCC] = useState('')
  const [pagina, setPagina] = useState('pedidos')


  const getPedidos = async () => {
    setLoading(true)
    const response = await axios.get(process.env.REACT_APP_ENDERECO_WS + '/pedidos')
    setLoading(false)
    if (response.status == 200) {
      setPedidos(response.data)
    }

  }
  const getNfes = async () => {
    setLoading(true)
    const response = await axios.get(process.env.REACT_APP_ENDERECO_WS + '/nfes')
    setLoading(false)
    if (response.status == 200) {
      setNfes(response.data)
      setPagina('notas')
    }

  }

  const getNotas = async (id) => {
    setNotas([])
    setLoading(true)
    const response = await axios.get(process.env.REACT_APP_ENDERECO_WS + '/notas/' + id)
    setLoading(false)
    if (response.status == 200) {
      setNotas(response.data)
    }

  }

  const enviarNFE = async (id) => {
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_ENDERECO_WS + '/notas/faturarv2', {
      pedido: id
    })
    setLoading(false)
    if (response.status == 200 && response.data.retorno.codigo == 'erro_validacao_schema') {
      alert('Erros na Emissão')
    }
  }

  const enviarCC = async (id, correcao) => {
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_ENDERECO_WS + '/notas/carta', {
      idPedido: id,
      motivo: correcao
    })

    setLoading(false)

    if (response.status == 200) {
      alert('Envio de Carta de Correção realizado com sucesso!')
      setMotivo('')
      setModalCC(false)
    }

  }

  const cancelarNFe = async (id) => {
    setLoading(true)
    const response = await axios.delete(process.env.REACT_APP_ENDERECO_WS + '/notas/' + id)
    setLoading(false)
    if (response.status == 200) {
      alert('Envio de Cancelamento realizado com sucesso!')
    }

  }

  const importarPedidos = async (pedidos) => {
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_ENDERECO_WS + '/pedidos/importar', {
      pedidos: pedidos
    })
    setLoading(false)
    if (response.status == 200) {
      alert(response.data.message)
    }
  }

  const AtualizarCliente = async (clientes) => {
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_ENDERECO_WS + '/clientes/importar', {
      clientes: clientes
    })
    setLoading(false)
    if (response.status == 200) {
      alert(response.data.message)
    }
  }
  const AtualizarProdutos = async (produtos) => {
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_ENDERECO_WS + '/produtos/importar', {
      produtos: produtos
    })
    setLoading(false)
    if (response.status == 200) {
      alert(response.data.message)
    }
  }

  const getPedido = async (id) => {
    setPedido({})
    setLoading(true)
    const response = await axios.get(process.env.REACT_APP_ENDERECO_WS + '/pedidos/' + id)
    setLoading(false)
    if (response.status == 200) {
      setPedido(response.data)
      setJanelaInfo(true)
    } else {
      alert("Pedido não encontrado!")
    }

  }

  const deletePedido = async (id) => {
    setLoading(true)
    const response = await axios.delete(process.env.REACT_APP_ENDERECO_WS + '/pedidos/' + id)
    setLoading(false)
    if (response.status == 200) {
      setJanelaInfo(false)
      getPedidos()
    } else {
      alert("Pedido não encontrado!")
    }

  }

  const alterarCampo = async (dados) => {
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_ENDERECO_WS + '/alterar/', {
      tabela: dados.tabela,
      campo: dados.campo,
      valor: dados.valor,
      condicao: dados.condicao,
      id: dados.id
    })
    setLoading(false)
    if (response.status == 200) {
      getPedidos()
    }
  }

  useEffect(() => {
    getPedidos()
  }, [])


  return (
    <div>
      <BarraNavegacao
        pagina={pagina}
        setPagina={setPagina}
        getNfes={getNfes}
      />
      {pagina === 'pedidos' &&
        <Container style={{ marginTop: 15, marginBottom: 15 }}>
          <GridPedidos
            data={pedidos}
            click={(rows) => {
              getPedido(rows.ped_pedido)
              getNotas(rows.ped_pedido)
            }}
          />
        </Container>
      }

      {pagina === 'importar' &&
        <Container style={{ marginTop: 15, marginBottom: 15 }}>
          <h3>Importação de Dados</h3>
          <TabsImportacao
            importarPedidos={importarPedidos}
            AtualizarCliente={AtualizarCliente}
            AtualizarProdutos={AtualizarProdutos}
          />
        </Container>
      }

      {pagina === 'notas' &&
        <Container style={{ marginTop: 15, marginBottom: 15 }}>
          <h3>Notas Fiscais Emitidas</h3>
          <GridNFEs
            data={nfes}
          />
        </Container>
      }

      <ModalInfoPedido
        estado={janelaInfo}
        setEstado={setJanelaInfo}
        pedido={pedido}
        notas={notas}
        cancelarNota={cancelarNFe}
        enviarNFE={enviarNFE}
        enviarCC={enviarCC}
        getNotas={getNotas}
        loading={loading}
        setLoading={setLoading}
        setModalCC={setModalCC}
        modalCC={modalCC}
        idPedido={idPedido}
        setIdPedido={setIdPedido}
        motivo={motivo}
        setMotivo={setMotivo}
        deletePedido={deletePedido}
        alterarCampo={alterarCampo}
      />
      <Modal
        show={loading}

      >

        <Modal.Body>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          <div className="d-flex justify-content-center" style={{ marginTop: 20 }}>
            <span>Processando Solicitação</span>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalCC} onHide={() => { setModalCC(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Carta de Correção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Correção</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                autoFocus
                value={motivo}
                onChange={(e) => {
                  setMotivo(e.target.value)
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setModalCC(false) }}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => {
            if (motivo.length >= 15 && motivo.length <= 1000) {
              enviarCC(idPedido, motivo)
            } else {
              alert('Descrição da Correção deve ter ao menos 15 caracteres, e no máximo 1000 caracteres')
            }
          }}>
            Gerar Carta de Correção
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default App;
