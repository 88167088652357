import React from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


export default function GridPedidos(props) {


    const colunas = [
        {
            name: 'Pedido',
            selector: row => row.ped_pedido,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Localizador',
            selector: row => row.ped_localizador,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Cliente',
            selector: row => row.clie_nome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Valor',
            selector: row => Number(row.total),
            format: row => Number(row.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            right: true,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Volumes',
            selector: row => row.volumes,
            sortable: true,
            right: true,
            wrap: true,
            maxWidth: '40px'
        },
        {
            name: 'NFe',
            selector: row => row.nota,
            sortable: true,
            right: true,
            wrap: true,
            maxWidth: '40px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            right: true,
            wrap: true,
            maxWidth: '160px'
        },

    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const tableDataConfig = {
        columns: colunas,
        data: props.data,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: false,
        export: false,
        filterDigit: 1
    };

    return (
        <DataTableExtensions {...tableDataConfig}>
            <DataTable
                striped={true}
                highlightOnHover={true}
                responsive={true}
                onRowClicked={row => props.click(row)}
                // dense
                pointerOnHover={true}
                noDataComponent={"Nenhum Pedido Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        </DataTableExtensions>
    )
}