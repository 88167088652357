import React, { useState } from "react";
import { Tabs, Tab, Form, Button, Col, Row, ButtonGroup, Modal } from "react-bootstrap";
import GridImportacao from "../gridImportacao";

export default function TabsImportacao(props) {


    const ambiente = 'P' //Produção ou Homologação
    const urlBase = ambiente == 'H' ? 'https://homologacao.focusnfe.com.br' : 'https://api.focusnfe.com.br'

    const [key, setKey] = useState('pedidos');
    // const [campoVolume, setCampoVolume] = useState(false);

    const [arquivoPedidos, setArquivoPedidos] = useState();
    const [arquivoClientes, setArquivoClientes] = useState();
    const [arquivoProdutos, setArquivoProdutos] = useState();



    const processarTexto = () => {

        const lines = arquivoPedidos.split('\n');

        const keys = ['pedido', 'localizador', 'cnpj', 'transportadora', 'item', 'descricao', 'um', 'qtde', 'preco', 'frete', 'brinde', 'icms', 'ipi', 'cfop', 'icms_situacao_trib', 'icms_origem', 'icms_bc', 'icms_valor', 'ipi_situacao_trib', 'ipi_bc', 'ipi_valor', 'icms_bc_uf_destino', 'fcp_bc_uf_destino', 'fcp_perc_uf_destino', 'icms_ali_interna_uf_destino', 'icms_aliq_interestadual', 'fcp_valor_uf_destino', 'icms_valor_uf_destino', 'st_bc', 'st_aliq', 'st_valor', 'perc_mva', 'mod_mva', 'volumes', 'peso', 'endereco_entrega'];

        const lista = [];

        for (let i = 0; i < lines.length; i++) {

            const values = lines[i].split('|');

            const json = keys.reduce((obj, key, index) => {
                obj[key] = values[index];
                return obj;
            }, {});

            lista.push(json);



        }

        props.importarPedidos(lista)

        console.log(JSON.stringify(lista));

    };

    const processarClientes = () => {

        const lines = arquivoClientes.split('\n');

        const keys = ['cnpj', 'nome', 'endereco', 'numero', 'complemento', 'bairro', 'uf', 'municipio', 'cep', 'tipo', 'fone', 'ie', 'contribuinte'];

        const lista = [];

        for (let i = 0; i < lines.length; i++) {

            const values = lines[i].split('|');

            const json = keys.reduce((obj, key, index) => {
                obj[key] = values[index];
                return obj;
            }, {});

            lista.push(json);


        }

        props.AtualizarCliente(lista)

        console.log(JSON.stringify(lista));

    };

    const processarProdutos = () => {

        const lines = arquivoProdutos.split('\n');

        const keys = ['referencia', 'descricao', 'um', 'tipo', 'codigoncm', 'cst_origem'];

        const lista = [];

        for (let i = 0; i < lines.length; i++) {

            const values = lines[i].split('|');

            const json = keys.reduce((obj, key, index) => {
                obj[key] = values[index];
                return obj;
            }, {});

            lista.push(json);


        }

        props.AtualizarProdutos(lista)

        console.log(JSON.stringify(lista));

    };


    return (
        <>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                style={{ marginTop: '60px' }}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            // variant="pills"
            >
                <Tab eventKey="pedidos" title="Pedidos">
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Dados para Importação (Dados Existentes serão ignorados)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={15}
                                autoFocus
                                value={arquivoPedidos}
                                onChange={(e) => setArquivoPedidos(e.target.value)}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={processarTexto}
                            >
                                Importar
                            </Button>
                        </div>
                    </Form>


                </Tab>
                <Tab eventKey="clientes" title="Clientes">
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Dados para Importação (Dados Existentes serão atualizados)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={15}
                                autoFocus
                                value={arquivoClientes}
                                onChange={(e) => setArquivoClientes(e.target.value)}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={processarClientes}
                            >
                                Importar
                            </Button>
                        </div>
                    </Form>

                </Tab>
                <Tab eventKey="produtos" title="Produtos">
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Dados para Importação (Dados Existentes serão atualizados)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={15}
                                autoFocus
                                value={arquivoProdutos}
                                onChange={(e) => setArquivoProdutos(e.target.value)}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={processarProdutos}
                            >
                                Importar
                            </Button>
                        </div>
                    </Form>

                </Tab>


            </Tabs>



        </>
    )
}