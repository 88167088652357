import React from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


export default function GridProdutos(props) {

    const colunas = [
        {
            name: 'Produto',
            selector: row => row.prod_referencia,
            sortable: true,
            wrap: true,
            maxWidth: '60px'
        },
        {
            name: 'Descrição',
            selector: row => row.prod_descricao,
            sortable: true,
            wrap: true,
        },
        {
            name: 'CFOP',
            selector: row => row.ped_cfop,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Quantidade',
            selector: row => Number(row.ped_qtde),
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Peso',
            selector: row => Number(row.ped_peso),
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'NCM',
            selector: row => row.prod_codigoncm,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        // {
        //     name: 'Valor',
        //     selector: row => Number(row.total.replace(',','.')),
        //     format: row => Number(row.total.replace(',','.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        //     right: true,
        //     sortable: true,
        //     wrap: true,
        //     maxWidth: '120px'
        // }

    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const tableDataConfig = {
        columns: colunas,
        data: props.data,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: false,
        export: false,
        filterDigit: 1
    };

    return (
        <DataTableExtensions {...tableDataConfig}>
            <DataTable
                striped={true}
                highlightOnHover={true}
                responsive={true}
                // onRowClicked={row => props.click(row)}
                dense
                pointerOnHover={true}
                noDataComponent={"Nenhum Pedido Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        </DataTableExtensions>
    )
}