import React from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


export default function GridNFEs(props) {


    const colunas = [
        {
            name: 'Número',
            selector: row => row.numero,
            cellExport: row => row.numero,
            sortable: true,
            wrap: true,
            maxWidth: '60px'
        },
        {
            name: 'Série',
            selector: row => row.serie,
            cellExport: row => row.serie,
            sortable: true,
            wrap: true,
            maxWidth: '60px'
        },
        {
            name: 'Pedido',
            selector: row => row.pedido,
            cellExport: row => row.pedido,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Localizador',
            selector: row => row.localizador,
            cellExport: row => row.localizador,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            cellExport: row => row.status,
            sortable: true,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: 'Protocolo',
            selector: row => row.protocolo,
            cellExport: row => row.protocolo,
            sortable: true,
            wrap: true
        },
        {
            name: 'Chave NFe',
            selector: row => row.nfe_chave_nfe,
            cellExport: row => row.nfe_chave_nfe,
            sortable: true,
        },
        {
            name: 'XML NFe',
            selector: row => row.xml_nfe,
            cellExport: row => row.xml_nfe,
            sortable: true,
        },
        {
            name: 'Danfe NFe',
            selector: row => row.danfe,
            cellExport: row => row.danfe,
            sortable: true,
        },
        {
            name: 'XML Carta Correção',
            selector: row => row.xml_carta_correcao,
            cellExport: row => row.xml_carta_correcao,
            sortable: true,
        },
        {
            name: 'Carta Correção',
            selector: row => row.pdf_carta_correcao,
            cellExport: row => row.pdf_carta_correcao,
            sortable: true,
        },

    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const tableDataConfig = {
        columns: colunas,
        data: props.data,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: false,
        cellExport: true,
        filterDigit: 1
    };

    return (
        <DataTableExtensions {...tableDataConfig}>
            <DataTable
                striped={true}
                highlightOnHover={true}
                responsive={true}
                // onRowClicked={row => props.click(row)}
                // dense
                pointerOnHover={true}
                noDataComponent={"Nenhuma NFe Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        </DataTableExtensions>
    )
}